import media from "../assets/press.png";
import blogpic from "../assets/ecomm.png";
import pri from "../assets/primary-logo.svg";
import flat from "../assets/flat.svg";
import fla from "../assets/flatlogo.svg";
import plm from "../assets/plm.svg";
import film from "../assets/film.svg";
import flm from "../assets/flm.svg";
import logos from "../assets/threelogo.png";
import { Link } from "react-router-dom";


function PressMedia() {
  return (
    <section className="pressmedia">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-6 text-center">
            <h2 className="text-white">Press & Media Resources</h2>
          </div>
        </div>
        <div className="row justify-content-center info pressm">
          <div className="col-sm-3 text-center">
            <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">News</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Logos & Badges</button>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
              <div className='row mt-5 justify-content-center'>
                <div className='col-sm-12'>
                  <div className="art">
                    <img src={media} alt="media" className="img-fluid" />
                    <div className="d-flex flex-row my-5">
                      <div className="me-2"><span className="tcrunch">Techcrunch</span></div>
                      <div><span className="text-white date-time">May 10, 2023</span></div>
                    </div>
                    <h3 className="text-white">How mobile banking is Changing the Face of Banking in Africa</h3>
                  </div>
                </div>
              </div>
              <div className="toppings ttle-press">
                <div className="row">
                  <div className="col-sm-5">
                    <h3 className="text-white">Press Release</h3>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-4 mb-3">
                    <div className="card-press">
                      <img src={blogpic} alt="blogimg" className="img-fluid w-100" />
                      <h5 className="text-white mt-4">May 10, 2023</h5>
                      <Link to="/single-post"><h4 className="my-4 text-white">Impact of mobile banking on<br/>commerce in Africa</h4></Link>
                      <p className="text-white">At Ardilla, our mission is to help people across the continent build wealth and achieve well thought out financial goals. We </p>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <div className="card-press">
                      <img src={blogpic} alt="blogimg" className="img-fluid w-100" />
                      <h5 className="text-white mt-4">May 10, 2023</h5>
                      <h4 className="my-4 text-white">Impact of mobile banking on<br/>commerce in Africa</h4>
                      <p className="text-white">At Ardilla, our mission is to help people across the continent build wealth and achieve well thought out financial goals. We </p>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <div className="card-press">
                      <img src={blogpic} alt="blogimg" className="img-fluid w-100" />
                      <h5 className="text-white mt-4">May 10, 2023</h5>
                      <h4 className="my-4 text-white">Impact of mobile banking on<br/>commerce in Africa</h4>
                      <p className="text-white">At Ardilla, our mission is to help people across the continent build wealth and achieve well thought out financial goals. We </p>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-4 mb-3">
                    <div className="card-press">
                      <img src={blogpic} alt="blogimg" className="img-fluid w-100" />
                      <h5 className="text-white mt-4">May 10, 2023</h5>
                      <h4 className="my-4 text-white">Impact of mobile banking on<br/>commerce in Africa</h4>
                      <p className="text-white">At Ardilla, our mission is to help people across the continent build wealth and achieve well thought out financial goals. We </p>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <div className="card-press">
                      <img src={blogpic} alt="blogimg" className="img-fluid w-100" />
                      <h5 className="text-white mt-4">May 10, 2023</h5>
                      <h4 className="my-4 text-white">Impact of mobile banking on<br/>commerce in Africa</h4>
                      <p className="text-white">At Ardilla, our mission is to help people across the continent build wealth and achieve well thought out financial goals. We </p>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <div className="card-press">
                      <img src={blogpic} alt="blogimg" className="img-fluid w-100" />
                      <h5 className="text-white mt-4">May 10, 2023</h5>
                      <h4 className="my-4 text-white">Impact of mobile banking on<br/>commerce in Africa</h4>
                      <p className="text-white">At Ardilla, our mission is to help people across the continent build wealth and achieve well thought out financial goals. We </p>
                    </div>
                  </div>
                </div>
                <div className="row toppings">
                  <div className="col-sm-5">
                    <h3 className="text-white">In the media</h3>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-4 mb-3">
                    <div className="card-press">
                      <img src={blogpic} alt="blogimg" className="img-fluid w-100" />
                      <div className="d-flex flex-row mt-4">
                        <div className="me-2"><span className="tcrunch">Techcrunch</span></div>
                        <div><span className="text-white date-time">May 10, 2023</span></div>
                      </div>
                      <h4 className="my-4 text-white">Impact of mobile banking on<br/>commerce in Africa</h4>
                      <p className="text-white">At Ardilla, our mission is to help people across the continent build wealth and achieve well thought out financial goals. We </p>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <div className="card-press">
                      <img src={blogpic} alt="blogimg" className="img-fluid w-100" />
                      <div className="d-flex flex-row mt-4">
                        <div className="me-2"><span className="tcrunch">Techcrunch</span></div>
                        <div><span className="text-white date-time">May 10, 2023</span></div>
                      </div>
                      <h4 className="my-4 text-white">Impact of mobile banking on<br/>commerce in Africa</h4>
                      <p className="text-white">At Ardilla, our mission is to help people across the continent build wealth and achieve well thought out financial goals. We </p>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <div className="card-press">
                      <img src={blogpic} alt="blogimg" className="img-fluid w-100" />
                      <div className="d-flex flex-row mt-4">
                        <div className="me-2"><span className="tcrunch">Vanguard</span></div>
                        <div><span className="text-white date-time">May 10, 2023</span></div>
                      </div>
                      <h4 className="my-4 text-white">Impact of mobile banking on<br/>commerce in Africa</h4>
                      <p className="text-white">At Ardilla, our mission is to help people across the continent build wealth and achieve well thought out financial goals. We </p>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-4 mb-3">
                    <div className="card-press">
                      <img src={blogpic} alt="blogimg" className="img-fluid w-100" />
                      <div className="d-flex flex-row mt-4">
                        <div className="me-2"><span className="tcrunch">Techcrunch</span></div>
                        <div><span className="text-white date-time">May 10, 2023</span></div>
                      </div>
                      <h4 className="my-4 text-white">Impact of mobile banking on<br/>commerce in Africa</h4>
                      <p className="text-white">At Ardilla, our mission is to help people across the continent build wealth and achieve well thought out financial goals. We </p>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <div className="card-press">
                      <img src={blogpic} alt="blogimg" className="img-fluid w-100" />
                      <div className="d-flex flex-row mt-4">
                        <div className="me-2"><span className="tcrunch">Daily News</span></div>
                        <div><span className="text-white date-time">May 10, 2023</span></div>
                      </div>
                      <h4 className="my-4 text-white">Impact of mobile banking on<br/>commerce in Africa</h4>
                      <p className="text-white">At Ardilla, our mission is to help people across the continent build wealth and achieve well thought out financial goals. We </p>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <div className="card-press">
                      <img src={blogpic} alt="blogimg" className="img-fluid w-100" />
                      <div className="d-flex flex-row mt-4">
                        <div className="me-2"><span className="tcrunch">Vanguard</span></div>
                        <div><span className="text-white date-time">May 10, 2023</span></div>
                      </div>
                      <h4 className="my-4 text-white">Impact of mobile banking on<br/>commerce in Africa</h4>
                      <p className="text-white">At Ardilla, our mission is to help people across the continent build wealth and achieve well thought out financial goals. We </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
              <div>
                <div className='row mt-5 justify-content-center logos-b'>
                  <div className='col-sm-12'>
                    <h4 className="text-white">Stackivy is a financial platform that has derived its logo inspiration from the Tree squirrels. These animals are known for their diligent nature as they work hard to save nuts and small fruits to prepare for the winter and colder days ahead. Moreover, Tree squirrels are also admired for their sharp-wittedness, making them an ideal representation for a financial platform that values intelligence and resourcefulness.</h4>
                  </div>
                  <div className="row mt-9">
                    <div className="col-sm-4 mb-3">
                      <img src={pri} alt="primary-logo" className="img-fluid w-100" />
                    </div>
                    <div className="col-sm-4 mb-3">
                      <img src={flat} alt="primary-logo" className="img-fluid w-100" />
                    </div>
                    <div className="col-sm-4 mb-3">
                      <img src={fla} alt="primary-logo" className="img-fluid w-100" />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-sm-4 mb-3">
                      <img src={plm} alt="primary-logo" className="img-fluid w-100" />
                    </div>
                    <div className="col-sm-4 mb-3">
                      <img src={film} alt="primary-logo" className="img-fluid w-100" />
                    </div>
                    <div className="col-sm-4 mb-3">
                      <img src={flm} alt="primary-logo" className="img-fluid w-100" />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col">
                      <h1 className="text-white">Don’ts</h1>
                      <h4 className="text-white mt-4">Our logo represents our brand and it's important that it's well presented consistently everywhere.</h4>
                      <div className="my-5">
                        <h6 className="text-white">No squashing or pulling<br/>No drop shadows</h6>
                        <h6 className="text-white">No gradients<br/>No tracing or recreation</h6>
                      </div>
                      <img src={logos} alt="primary-logo" className="img-fluid w-100" />

                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default PressMedia;
