

function LendingHero() {
  const handleClickScroll = () => {
    const element = document.getElementById('startbuilding');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <section className="lending-hero">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-9 text-center">
            <h2 className="animate__animated animate__slideInDown animate__fast">Explore multiple <span className="cred">credit solutions</span><br/>for any financial challenge.</h2>
            <h6 className="my-5">Our lending platforms Hargon, Curved, Nearly</h6>
            <button
              className="btn btn-primary btn-green rounded-pill px-5 py-4"
              onClick={handleClickScroll} style={{background: 'linear-gradient(117.04deg, #2563EB -13.79%, #35FF49 131.52%)'}}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LendingHero;
