import React, { useEffect, useState } from "react";
import Header from "../components/navBar";
import BlogContent from "../components/blogContent";
import NoTop from "../components/footerwithoutTop";
import axios from "axios";
import { baseUrl } from "../baseUrl";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    const getBlogs = async () => {
      try {
        const { data } = await axios.get(
          `${baseUrl}/api/v1/stackivy/admin/marketing/blog`
        );

        if (data.code === 200) {
          console.log(data);
          setBlogs(data.blogs);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getBlogs();
    return () => {};
  }, []);

  return (
    <div>
      <Header />
      <BlogContent data={blogs} />
      <NoTop />
    </div>
  );
}

export default Blogs;
