import live from "../assets/sad.svg";
import date from "../assets/date.svg";
import map from "../assets/map.svg";
import { format } from "date-fns";

function PastWebinar({ data, isLoading }) {
  const getMeridian = (time) => {
    const hour = Number(time.slice(0, 2));
    let meridian;
    if (hour < 12) {
      meridian = "AM";
    } else if (hour === 12) {
      meridian = "PM";
    } else meridian = "PM";

    return meridian;
  };
  return (
    <section className="info" style={{ background: "#EFF5F9" }}>
      <div className="container">
        <div className="row justify-content-center mobileinfo">
          <div className="col-sm-4 text-center">
            <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  Past Webinars
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="false"
                >
                  Live Webinars
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active mt-9"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabIndex="0"
            >
              {isLoading === true && (
                <p className="text-center mt-5">Loading...</p>
              )}
              {data?.inactive_webinar?.map((w, i) => (
                <div className="row mt-5 justify-content-between" key={i}>
                  <div className="col-sm-6 mb-3">
                    <img
                      src={w.image.file_url}
                      alt="Past Webinar"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-sm-6 past-web">
                    <span
                      className="closed"
                      style={{ background: "#E1EDF4", color: "#000" }}
                    >
                      closed
                    </span>
                    <h2 className="mt-3">{w.title}</h2>
                    <p className="my-5">{w.summary}</p>
                    <span
                      className="closed brd"
                      style={{
                        background: "#004EE1",
                        color: "#fff",
                        borderRadius: "0px",
                      }}
                    >
                      Guest speaker
                    </span>
                    <div
                      className="mt-3 mb-4 speakers"
                      style={{ display: "flex", flexWrap: "wrap", gap: "14px" }}
                    >
                      {w?.webinar_info?.speakers?.map((s, i) => (
                        <h6 key={i}>{s}</h6>
                      ))}
                    </div>
                    <div
                      className=""
                      style={{ display: "flex", flexWrap: "wrap", gap: "25px" }}
                    >
                      <h3>
                        <img
                          src={date}
                          alt="date"
                          className="img-fluid me-1"
                          style={{ marginTop: "-4px" }}
                        />{" "}
                        {w.webinar_info?.date}
                        {format(new Date(w.webinar_info?.date), "do LLL, yyyy")}
                      </h3>

                      <h3>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="blue"
                          width={16}
                          height={16}
                          style={{ marginTop: "-4px", marginRight: "6px" }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        {w?.webinar_info?.time}{" "}
                        {getMeridian(w?.webinar_info.time)}
                      </h3>

                      <h3>
                        <img
                          src={map}
                          alt="date"
                          className="img-fluid me-1"
                          style={{ marginTop: "-4px" }}
                        />{" "}
                        {w?.webinar_info?.location}
                      </h3>
                    </div>
                    {/* <h3 className="">The webinar will cover:</h3>
                    <div className="mt-4">
                      <div className="d-flex flex-row mb-3">
                        <div className="me-3">
                          <img src={dot} alt="dot" className="img-fluid" />
                        </div>
                        <div>
                          <h4 className="mt-1">
                            Challenges of sustainable practices in Africa
                          </h4>
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="me-3">
                          <img src={dot} alt="dot" className="img-fluid" />
                        </div>
                        <div>
                          <h4 className="mt-1">
                            Is Africa ripe for sustainable products?
                          </h4>
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="me-3">
                          <img src={dot} alt="dot" className="img-fluid" />
                        </div>
                        <div>
                          <h4 className="mt-1">
                            How do we tackle the challenges of sustainable
                            financial solutions in Africa
                          </h4>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="tab-pane fade"
              id="profile-tab-pane"
              role="tabpanel"
              aria-labelledby="profile-tab"
              tabIndex="0"
            >
              <div className="row mt-5">
                <div className="col-sm-12">
                  {isLoading === true && (
                    <p className="text-center mt-5">Loading...</p>
                  )}
                  {data?.active_webinar?.length === 0 && (
                    <div className="text-center live-card">
                      <img src={live} alt="live" className="img-fluid" />
                      <h6 className="mt-4">
                        You just missed it!. No upcoming webinars, check back
                        later
                      </h6>
                    </div>
                  )}

                  {data?.active_webinar?.map((w, i) => (
                    <div className="row mt-5 justify-content-between" key={i}>
                      <div className="col-sm-6 mb-3">
                        <img
                          src={w.image.file_url}
                          alt="Past Webinar"
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-sm-6 past-web">
                        <span
                          className="closed"
                          style={{ background: "#E1EDF4", color: "#000" }}
                        >
                          Live
                        </span>
                        <h2 className="mt-3">{w.title}</h2>
                        <p className="my-5">{w.summary}</p>

                        <span
                          className="closed brd"
                          style={{
                            background: "#004EE1",
                            color: "#fff",
                            borderRadius: "0px",
                          }}
                        >
                          Guest speaker
                        </span>
                        <div
                          className="mt-3 mb-4 speakers"
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "16px",
                          }}
                        >
                          {w?.webinar_info?.speakers?.map((s, i) => (
                            <h6 key={i}>{s}</h6>
                          ))}
                        </div>

                        <div
                          className=""
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "25px",
                          }}
                        >
                          <h3>
                            <img
                              src={date}
                              alt="date"
                              className="img-fluid me-1"
                              style={{ marginTop: "-4px" }}
                            />{" "}
                            {format(
                              new Date(w.webinar_info?.date),
                              "do LLL, yyyy"
                            )}
                          </h3>

                          <h3>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="blue"
                              width={16}
                              height={16}
                              style={{ marginTop: "-4px", marginRight: "6px" }}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            {w?.webinar_info?.time}{" "}
                            {getMeridian(w?.webinar_info.time)}
                          </h3>

                          <h3>
                            <img
                              src={map}
                              alt="date"
                              className="img-fluid me-1"
                              style={{ marginTop: "-4px" }}
                            />{" "}
                            {w?.webinar_info?.location}
                          </h3>
                        </div>
                        {/* <h3 className="">The webinar will cover:</h3> */}
                        {/* <div className="mt-4">
                          <div className="d-flex flex-row mb-3">
                            <div className="me-3">
                              <img src={dot} alt="dot" className="img-fluid" />
                            </div>
                            <div>
                              <h4 className="mt-1">
                                Challenges of sustainable practices in Africa
                              </h4>
                            </div>
                          </div>
                          <div className="d-flex flex-row mb-3">
                            <div className="me-3">
                              <img src={dot} alt="dot" className="img-fluid" />
                            </div>
                            <div>
                              <h4 className="mt-1">
                                Is Africa ripe for sustainable products?
                              </h4>
                            </div>
                          </div>
                          <div className="d-flex flex-row mb-3">
                            <div className="me-3">
                              <img src={dot} alt="dot" className="img-fluid" />
                            </div>
                            <div>
                              <h4 className="mt-1">
                                How do we tackle the challenges of sustainable
                                financial solutions in Africa
                              </h4>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PastWebinar;
