import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { baseUrl } from "../baseUrl";

function BeSpeaker() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [speaker, setSpeaker] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    linkedin: "",
    instagram: "",
    note: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await axios.post(
        `${baseUrl}/api/v1/stackivy/admin/marketing/webinar/speaker`,
        speaker
      );
      setIsLoading(false);
      setMessage("speaker info recorded succesfully");
    } catch (error) {
      setMessage(error.message);
      console.log(error);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setSpeaker({
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
          linkedin: "",
          instagram: "",
          note: "",
        });
        setMessage();
      }, 3000);
    }
  };

  return (
    <section className="bespeaker">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-6 text-center">
            <h2>Be a speaker on our next webinar</h2>
            <p className="mt-4">
              Would you like to take charge in the financial sector? Share
              knowledge and impact lives? Be a speaker on our next webinar and
              wait for an invitation.
            </p>
          </div>
        </div>
        <div className="row mt-5 justify-content-center info">
          <div className="col-sm-10">
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={speaker.first_name}
                      onChange={(e) =>
                        setSpeaker({ ...speaker, first_name: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={speaker.last_name}
                      onChange={(e) =>
                        setSpeaker({ ...speaker, last_name: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="tel"
                      required
                      value={speaker.phone}
                      onChange={(e) =>
                        setSpeaker({ ...speaker, phone: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      required
                      value={speaker.email}
                      onChange={(e) =>
                        setSpeaker({ ...speaker, email: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Linkedin</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={speaker.linkedin}
                      onChange={(e) =>
                        setSpeaker({ ...speaker, linkedin: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Instagram</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={speaker.instagram}
                      onChange={(e) =>
                        setSpeaker({ ...speaker, instagram: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="my-4">
                <Form.Label>Introduce yourself</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Something we should know before our sales team contact you"
                  required
                  value={speaker.note}
                  onChange={(e) =>
                    setSpeaker({ ...speaker, note: e.target.value })
                  }
                />
              </Form.Group>
              <div className="text-center">
                <button
                  className="btn btn-primary btn-green rounded-pill px-5 py-4 mt-5"
                  to=""
                  style={{ background: "#F4CD3E", color: "#000" }}
                  disabled={isLoading}
                >
                  {isLoading ? "processing..." : "  Submit"}
                </button>
                <p className="mt-5">{message}</p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BeSpeaker;
