
import phone from "../assets/phon.png";

function RemitVernde() {
  return (
    <section className="rwvv">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-7 text-center">
            <h2>Remit with Vernde</h2>
            <p className="mt-4">Working abroad alone is hard; sharing love with family doesn't have to be the same. At Vernde, we made it simple and seamless. Enjoy quick and convenient transfer of funds to family and friends anywhere in the world using Vernde. </p>
          </div>
        </div>
        <div className="row justify-content-center mt-9">
          <div className="col-sm-5 text-center">
            <img src={phone} alt="vernde" className="img-fluid w-100"  style={{marginLeft: "65px"}} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <div className="vernde-bg vnf">
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <h3 className="mb-3 text-white">Receive cash gifts with Vernde.</h3>
                  <p className="text-white mb-5">Receive cash gifts and payments from family and friends abroad swiftly using the Vernde remittance network. Treat yourself to a well-deserved secure and simplified payments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RemitVernde;