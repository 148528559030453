import { Accordion } from "react-bootstrap";

function FAQS() {
  return (
    <section className="faq">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-6 text-center">
            <h2>FAQ</h2>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Is Stackivy a comprehensive financial platform?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Absolutely! Stackivy is more than just a financial platform;
                  it's a comprehensive suite of innovative financial solutions.
                  From digital banking and wealth management to business finance
                  and children's financial services, Stackivy covers a wide
                  range of financial needs. Think of Stackivy as your all-in-one
                  destination for managing your finances and achieving your
                  goals.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How many products are available on Stackivy?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Stackivy offers a diverse range of products tailored to
                  different financial needs. We have five flagship products:
                  Ardilla, Hargon, Curved, Onwa, and Onescud . Each product
                  addresses specific financial requirements and provides
                  personalized solutions. We continuously work on expanding our
                  product offerings to meet the evolving needs of our clients.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {" "}
                  Are all Stackivy products accessible to me?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Yes! We strive to make all our products accessible to
                  individuals and businesses alike. Our goal is to provide
                  financial solutions catering to a wide range of users, from
                  individuals seeking personal financial management tools to
                  enterprises needing business financing. At Stackivy, we
                  believe in inclusivity and accessibility for everyone.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How can I register or use any of Stackivy's products?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Registering for Stackivy and accessing our products is simple
                  and user-friendly. Just visit our website and follow the easy
                  registration process. Once registered, you'll gain access to
                  our platform to explore and utilize the various products
                  available. We provide intuitive interfaces and step-by-step
                  guidance to ensure a smooth user experience.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  {" "}
                  Can I use Stackivy for both business and personal purposes?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Absolutely! Stackivy caters to both personal and business
                  financial needs. Whether you're looking to manage your
                  finances or a business owner seeking financing solutions,
                  Stackivy has you covered. Our suite of products offers
                  tailored features for both personal and business users,
                  ensuring that you find the right solutions for your specific
                  requirements.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  {" "}
                  How do I identify Stackivy products that suit my needs?{" "}
                </Accordion.Header>
                <Accordion.Body>
                Identifying the right Stackivy product for your needs is easy. We provide detailed information on each product, including its features and benefits, on our website. Additionally, you can contact our customer support team for personalized assistance. We are always here to help you find the perfect solution that aligns with your financial goals and requirements.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  {" "}
                  Can I get customizable financial products on Stackivy? 
                </Accordion.Header>
                <Accordion.Body>
                Absolutely! We understand that every individual and business has unique financial needs. That's why we offer customizable platform called Onescud. Our team works closely with you or your business to understand your requirements and tailor our solutions accordingly. Whether customizing wealth management strategies or creating personalized business financing plans, Stackivy is committed to providing flexible and customized options.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  {" "}
                  How much does it cost to use Stackivy products?
                </Accordion.Header>
                <Accordion.Body>
                At Stackivy, we believe in transparent and competitive pricing. The costs associated with using our products vary based on the specific product and services you choose. We offer various subscription plans and pricing models to suit different user needs, visit our subscription page for more information about the right product for you. Rest assured, we strive to provide cost-effective solutions without compromising quality or functionality.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  {" "}
                  Are Stackivy products tailored to industries?
                </Accordion.Header>
                <Accordion.Body>
                Yes! Stackivy understands that different industries have unique financial requirements. Our products are adaptable to various industries, providing industry-specific features and solutions. Whether in healthcare, technology, retail, or any other sector, you can customize stackivy's products to address your industry's specific challenges and opportunities.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  {" "}
                  What are the limitations of Stackivy products?
                </Accordion.Header>
                <Accordion.Body>
                While Stackivy strives to provide comprehensive financial solutions, it's important to note that our products have specific limitations. These limitations vary depending on the product and the services it offers. We transparently communicate any restrictions or limitations associated with each product on our website and during registration. Please call our support to discuss the limitations and your worries or  review the product details and terms of use to understand potential limitations that may affect your needs.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  {" "}
                  Do I get a Certificate for Stackivy's learn platform?
                </Accordion.Header>
                <Accordion.Body>
                Absolutely! We understand the value of learning and education. Upon completing courses or programs on Stackivy's learning platform, you will receive a certificate of completion. These certificates can be valuable additions to your professional profile, demonstrating your commitment to expanding your financial knowledge and skills.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  {" "}
                  Do my kids get a certificate on Keed?
                </Accordion.Header>
                <Accordion.Body>
                 
                Yes, indeed! At Stackivy, we believe in fostering financial literacy from an early age. When your kids complete financial courses or programs on our dedicated platform, Keed, they will receive certificates of achievement. These certificates serve as recognition for their dedication to learning about money management and financial responsibility.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  {" "}
                  How much interest can I expect when I take a loan on Hargon?
                </Accordion.Header>
                <Accordion.Body>
                The interest rates for loans on Hargon vary depending on various factors, including the specific loan product, your creditworthiness, and market conditions. We offer competitive and flexible interest rates tailored to your financial profile and loan requirements. We aim to provide you with fair and transparent lending options, ensuring you can access the funds you need while maintaining affordability.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  {" "}
                  Are Stackivy's risk assessments well vetted?
                </Accordion.Header>
                <Accordion.Body>
                Absolutely! Risk assessments are a crucial part of our financial solutions. We take risk management seriously and employ robust methodologies to ensure our risk assessments are accurate and reliable. Our team of experts continuously evaluates and enhances our risk assessment models, incorporating industry best practices and regulatory guidelines. Rest assured; you can rely on Stackivy's well-vetted risk assessments to make informed financial decisions.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="14">
                <Accordion.Header>
                  {" "}
                  Does Stackivy offer support for start-ups?
                </Accordion.Header>
                <Accordion.Body>
                Absolutely! We understand the unique challenges start-ups face and are here to support you on your entrepreneurial journey. Stackivy offers a range of financial solutions tailored to the needs of start-ups, including business loans, financial planning tools, and expert guidance. We aim to help start-ups thrive by providing them with the financial support and resources to succeed.

                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQS;
