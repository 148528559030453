import React from 'react'
import Header from "../components/navBar";
import ProductHome from '../components/productHome';




function ProducthomePage() {
  return (
    <div> 
      <Header />
      <ProductHome />
      
    </div>
    
  )
}

export default ProducthomePage