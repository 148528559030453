import users from "../assets/30plus.svg";


function NearlyHero() {
  return (
    <section className="nearly-hero">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-8 text-center" style={{marginTop: "-256px"}}>
            <h2 className="text-white">Bank with Zero limitations on your mobile</h2>
            <p className="my-5 text-white">Send and receive money to anyone, anywhere, anytime, using Stackivy's<br/>simplified mobile money platform</p>
            <div><img src={users} alt="users" className="img-fluid" /></div>
            <button
              className="btn pe-none btn-primary btn-green rounded-pill px-5 py-4 mt-5"
               style={{background: '#6AF938', color: "#000"}}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NearlyHero;
