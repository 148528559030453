import topbar from "../assets/h.svg";
import ver from "../assets/near.svg";
import ard from "../assets/smoon.png";
import feature from "../assets/features.svg";
import checklist from "../assets/checklist.svg";
import keed from "../assets/ha.svg";
import ardlogo from "../assets/sym.svg";
import po from "../assets/cd.svg";
import onwa from "../assets/ze.svg";
import ven from "../assets/nearl.svg";
import onw from "../assets/z.svg";
import pomelo from "../assets/cu.svg";
import pom from "../assets/ole.svg";
import p from "../assets/pooo.svg";


function BusinessSolution() {
  return (
    <section className="personal-solution bg-white pt-0">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <h6>Businesss Solutions</h6>
            <h2 className="my-4 all-in-one">Business Solutions Pricing</h2>
            <p>Empower your business with financial tools</p>
          </div>
        </div>
        <div className="toppings">
          <div className="row">
            <div className="col-sm-4 mb-3">
              <img src={topbar} alt="top" className="img-fluid w-100" />
              <div className="card-price">
                <h5 className="text-center mb-4">Varies based on<br/>loan amount</h5>
                <img src={feature} alt="feature" className="img-fluid w-100 mb-4" />
                <div className="fea">
                  <div className="d-flex">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Lending platform</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Personal loans</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Business loans</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Lines of credit</p></div>
                  </div>
                </div>
                <div className="mt-5">
                  <img src={keed} alt="keed" className="img-fluid float-end" />
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <img src={ard} alt="top" className="img-fluid w-100" />
              <div className="card-price">
                <h5 className="text-center mb-4">Starting at<br/>$16.99/month</h5>
                <img src={feature} alt="feature" className="img-fluid w-100 mb-4" />
                <div className="fea">
                  <div className="d-flex">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Open banking platform</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Financial data integration</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Multi-bank access</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Payment per API</p></div>
                  </div>
                </div>
                <div className="mt-5">
                  <img src={ardlogo} alt="keed" className="img-fluid float-end" />
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <img src={ver} alt="top" className="img-fluid w-100" />
              <div className="card-price">
                <h5 className="text-center" style={{marginBottom: "40px"}}>Starting at<br/>$7/transactions + N1,500 PayWrist</h5>
                <img src={feature} alt="feature" className="img-fluid w-100 mb-4" />
                <div className="fea">
                  <div className="d-flex">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Mobile money platform</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Mobile payments</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Peer-to-peer transfers</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Pay wrist</p></div>
                  </div>
                </div>
                <div className="mt-5">
                  <img src={ven} alt="keed" className="img-fluid float-end" />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-sm-4 mb-3">
              <img src={onw} alt="top" className="img-fluid w-100" />
              <div className="card-price">
                <h5 className="text-center" style={{marginBottom: "40px"}}>Starting at<br/>0.5% transaction fee</h5>
                <img src={feature} alt="feature" className="img-fluid w-100 mb-4" />
                <div className="fea">
                  <div className="d-flex">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Cryptocurrency and foreign exchange trading platform</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Buy and sell digital and foreign currencies.</p></div>
                  </div>
                </div>
                <div className="mt-5">
                  <img src={onwa} alt="keed" className="img-fluid float-end" />
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <img src={pomelo} alt="top" className="img-fluid w-100" />
              <div className="card-price">
                <h5 className="text-center" style={{marginBottom: "40px"}}>Varies based on services<br/>required + N1,500 PayWrist</h5>
                <img src={feature} alt="feature" className="img-fluid w-100 mb-4" />
                <div className="fea">
                  <div className="d-flex">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Microfinance solutions</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Loans</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Savings accounts</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Insurance</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Pay wrist</p></div>
                  </div>
                </div>
                <div className="mt-5">
                  <img src={po} alt="keed" className="img-fluid float-end" />
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <img src={pom} alt="top" className="img-fluid w-100" />
              <div className="card-price">
                <h5 className="text-center" style={{marginBottom: "40px"}}>Custom pricing based on<br/>requirements</h5>
                <img src={feature} alt="feature" className="img-fluid w-100 mb-4" />
                <div className="fea">
                  <div className="d-flex">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Custom-made financial system solutions</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Software development</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Consulting</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Management services</p></div>
                  </div>
                </div>
                <div className="mt-5">
                  <img src={p} alt="keed" className="img-fluid float-end" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BusinessSolution;
