import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";
import "./App.css";
import "./auth.css";
import "animate.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import Ardilla from "./pages/ardilla";
import Hargon from "./pages/hargon";
import Budgeting from "./pages/budgeting";
import FinancialEducation from "./pages/financialEducation";
import Wealth from "./pages/wealthManagement";
import CrossBorder from "./pages/crossBorderPayment";
import Pomelo from "./pages/pomelo";
import Crypto from "./pages/crypto";
import MicroFinance from "./pages/microFinance";
import MobileMoney from "./pages/mobilemoney";
import Lending from "./pages/lending";
import ScrollToTop from "./scrollToTop";
import CustomMade from "./pages/custom";
import BookKeep from "./pages/bookKeeping";
import OpenBanking from "./pages/openBanking";
import HealthCare from "./pages/healthCare";
import Ecommerce from "./pages/ecommerce";
import Education from "./pages/education";
import Food from "./pages/food";
import HR from "./pages/hr";
import Logistics from "./pages/logistics";
import ForStartup from "./pages/forStartup";
import Webinar from "./pages/webinar";
import Events from "./pages/events";
import Supports from "./pages/support";
import ProductIntegration from "./pages/productIntegration";
import AboutUs from "./pages/aboutUs";
import Careers from "./pages/careers";
import CaseStudies from "./pages/CaseStudies";
import Pricing from "./pages/pricing";
import Press from "./pages/press";
import SInglePost from "./pages/singlePost";
import FAQ from "./pages/faq";
import Nearly from "./pages/nearly";
import Partnership from "./pages/partnership";
import Error from "./pages/error";
import FinanceBlog from "./pages/financeBlog";
import CryptoBlog from "./pages/cryptoBlog";
import BookeepingBlog from "./pages/bookeepingBlog";
import MaximiseGuides from "./pages/maximiseGuides";
import CustomGuides from "./pages/customGuides";
import Blogs from "./pages/blogs";
import PrivacyPolicy from "./pages/privacyPolicy";
import PayplatformBlog from "./pages/payplatformBlog";
import TermsCondition from "./pages/termsCondition";
import Termsofuse from "./pages/termsofuse";
import Guides from "./pages/guides";
import SecureloanGuides from "./pages/secureloanGuides";
import BuildbetterGuides from "./pages/buildbetterGuides";
import FinancialfreedomGuides from "./pages/FinancialfreedomGuides";
import PomeloGuides from "./pages/pomeloGuides";
import StackproductGuides from "./pages/stackproductGuides";
import StacksolutionGuides from "./pages/stacksolutionGuides";
import ProducthomePage from "./pages/producthomePage";
import Singleblogpost from "./components/Singleblogpost";

// Test

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider
    breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
    minBreakpoint="xxs"
  >
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/partnership" element={<Partnership />}></Route>
        <Route path="/faqs" element={<FAQ />}></Route>
        <Route path="/nearly" element={<Nearly />}></Route>
        <Route path="/pricing" element={<Pricing />}></Route>
        <Route path="/press" element={<Press />}></Route>
        <Route path="/single-post" element={<SInglePost />}></Route>
        <Route path="/ardilla" element={<Ardilla />}></Route>
        <Route path="/hargon" element={<Hargon />}></Route>
        <Route path="/budgeting-tools" element={<Budgeting />}></Route>
        <Route
          path="/financial-education"
          element={<FinancialEducation />}
        ></Route>
        <Route path="/wealth-management" element={<Wealth />}></Route>
        <Route path="/cross-border" element={<CrossBorder />}></Route>
        <Route path="/payment-gateway" element={<Pomelo />}></Route>
        <Route path="/cryptocurrency" element={<Crypto />}></Route>
        <Route path="/microfinance" element={<MicroFinance />}></Route>
        <Route path="/mobile-money" element={<MobileMoney />}></Route>
        <Route path="/lending" element={<Lending />}></Route>
        <Route path="/custom-made" element={<CustomMade />}></Route>
        <Route path="/book-keeping" element={<BookKeep />}></Route>
        <Route path="/open-banking" element={<OpenBanking />}></Route>
        <Route path="/healthcare" element={<HealthCare />}></Route>
        <Route path="/ecommerce" element={<Ecommerce />}></Route>
        <Route path="/education" element={<Education />}></Route>
        <Route path="/food" element={<Food />}></Route>
        <Route path="/human-resources" element={<HR />}></Route>
        <Route path="/logistics" element={<Logistics />}></Route>
        <Route path="/for-startup" element={<ForStartup />}></Route>
        <Route path="/webinar" element={<Webinar />}></Route>
        <Route path="/events" element={<Events />}></Route>
        <Route path="/support" element={<Supports />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/careers" element={<Careers />}></Route>
        <Route path="/case-studies" element={<CaseStudies />}></Route>
        <Route
          path="/product-integration"
          element={<ProductIntegration />}
        ></Route>
        <Route path="/404" element={<Error />}></Route>
        <Route path="*" element={<Navigate to="/404" replace />}></Route>
        <Route path="/blog/finance" element={<FinanceBlog />}></Route>
        <Route
          path="/blog/paymentplatform"
          element={<PayplatformBlog />}
        ></Route>
        <Route path="/blog/crypto" element={<CryptoBlog />}></Route>
        <Route path="/blog/bookeeping" element={<BookeepingBlog />}></Route>
        <Route path="/blog/post/:id" element={<Singleblogpost />}></Route>
        <Route path="/blog" element={<Blogs />}></Route>
        <Route
          path="/guides/customapplication"
          element={<CustomGuides />}
        ></Route>
        <Route
          path="/guides/maximiseproduct"
          element={<MaximiseGuides />}
        ></Route>
        <Route path="/guides/secureloan" element={<SecureloanGuides />}></Route>
        <Route path="/guides" element={<Guides />}></Route>
        <Route path="/terms&conditions" element={<TermsCondition />}></Route>
        <Route path="/termsofuse" element={<Termsofuse />}></Route>
        <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
        <Route
          path="/guides/buildbetter"
          element={<BuildbetterGuides />}
        ></Route>
        <Route
          path="/blog/financialfreedom"
          element={<FinancialfreedomGuides />}
        ></Route>
        <Route
          path="/guides/implementpomelo"
          element={<PomeloGuides />}
        ></Route>
        <Route
          path="/guides/stack-ivyproducts"
          element={<StackproductGuides />}
        ></Route>
        <Route
          path="/guides/productsandsolutions"
          element={<StacksolutionGuides />}
        ></Route>
        <Route path="/product/homepage" element={<ProducthomePage />}></Route>
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);

reportWebVitals();
