import media from "../assets/press.png";
import copy from "../assets/copylink.svg";
import f from "../assets/flogo.svg";
import t from "../assets/tlogo.svg";
import i from "../assets/llogo.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import Replies from "./replies";

function PostSingle() {
  const [value, setValue] = useState("");
  const onInput = (e) => setValue(e.target.value);
  const onClear = () => {
    setValue("");
  };
  return (
    <section className="pressmedia">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-10 text-center">
            <div className="art">
              <div className="d-flex flex-row my-5 justify-content-center">
                <div className="me-2"><span className="tcrunch">Techcrunch</span></div>
                <div><span className="text-white date-time">May 10, 2023</span></div>
              </div>
              <h4 className="text-white">How mobile banking is Changing the Face of Banking in Africa</h4>
              <p className="text-white mt-4">Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus.<br/>Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-12">
            <img src={media} alt="media" className="img-fluid" />
          </div>
        </div>
        <div className="row mt-9 justify-content-center">
          <div className="col-sm-9">
            <div className="card-gray mb-5">
              <p>Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
              <p>Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
            </div>
            <hr className="mt-9" />
          </div>
        </div>
        <div className="body-press mt-9">
          <div className="row justify-content-center">
            <div className="col-sm-9">
              <p>Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
              <p className="my-3">Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
              <p>Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
              <img src={media} alt="media" className="img-fluid my-5" />
              <p>Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
              <p className="my-3">Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
              <p>Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
              <div className="mt-5">
                <h4 className="mb-4 text-white">Introduction</h4>
                <p>Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
                <p className="my-3">Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
                <p>Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
                <img src={media} alt="media" className="img-fluid my-5" />
              </div>
              <div className="mt-5">
                <h4 className="mb-4 text-white">Conclusion</h4>
                <p>Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
                <p className="my-3">Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
                <p>Lorem ipsum dolor sit amet consectetur. Eu pharetra felis parturient egestas vel faucibus. Nulla donec porta enim magna dignissim. Neque ipsum facilisis magna pharetra fusce tincidunt velit purus viverra. </p>
                <hr className="mt-5" />
              </div>
              <div className="d-flex flex-row justify-content-end mt-4">
                <Link><img src={copy} alt="copylink" className="img-fluid me-2" /></Link>
                <Link><img src={f} alt="copylink" className="img-fluid me-2" /></Link>
                <Link><img src={t} alt="copylink" className="img-fluid me-2" /></Link>
                <Link><img src={i} alt="copylink" className="img-fluid" /></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 justify-content-center">
          <div className="col-sm-9">
            <div className="bg-get text-center">
              <h1>Get More Informations Directly</h1>
              <p className="mt-3">Enter your email to get informations directly to you</p>
              <div className="row justify-content-center mt-4">
                <div className="col-sm-6 text-center">
                  <div className="subscribe">
                    <div className="input-group">
                      <input type="email" className="form-control" placeholder="Enter your email" value={value} onInput={onInput}/>
                      <span className="input-group-btn">
                        <button className="btn" type="button"  onClick={onClear}>Subscribe</button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-9" />
          </div>
        </div>
        <Replies/>
      </div>
    </section>
  );
}

export default PostSingle;
