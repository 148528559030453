import BusinessSolution from "../components/businessSolution";
import NoTop from "../components/footerwithoutTop";
import GetQuote from "../components/getQuote";
import Header from "../components/navBar";
import PersonalSolution from "../components/personalSolution";
import PricingHero from "../components/pricingHero";


function Pricing() {
  return (
    <div>
      <Header/>
      <PricingHero/>
      <PersonalSolution/>
      <BusinessSolution/>
      <GetQuote/>
      <NoTop/>
    </div>
  );
}

export default Pricing;
