import NoTop from "../components/footerwithoutTop";
import PressHeader from "../components/pressHeader";
import PressMedia from "../components/pressMedia";



function Press() {
  return (
    <div>
      <PressHeader/>
      <PressMedia/>
      <NoTop/>
      
    </div>
  );
}

export default Press;
