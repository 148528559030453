import React from "react";
import Header from "../components/navBar";
import BlogfinanceContent from "../components/blogfinanceContent";
import NoTop from "../components/footerwithoutTop";

function FinanceBlog() {
  return (
    <div>
      <Header />
      <BlogfinanceContent />
       <NoTop />
    
    </div>
  );
}

export default FinanceBlog;
