import React from 'react'
import Header from "../components/navBar";
import Buildbetter from '../components/buildbetter';
import NoTop from "../components/footerwithoutTop";

function BuildbetterGuides() {
  return (
    
    <div> 
    <Header />
    <Buildbetter/>
    <NoTop />
  </div>
  );
}




export default BuildbetterGuides;