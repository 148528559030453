import React from 'react'

function Privacybreadcrumb() {
  return (
    <section className="tbc">
    <div className="container">
     <div className='row justify-content-center text-center my-5'>
         <div>
             <h1 className='mb-3'>Privacy Policy</h1>
             <p> Last Updated: November 15, 2022</p>
         </div>

     </div>
     </div>  
 </section>
  );
}

export default Privacybreadcrumb;