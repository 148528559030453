import NoTop from "../components/footerwithoutTop";
import PostSingle from "../components/postSingle";
import PressHeader from "../components/pressHeader";



function SInglePost() {
  return (
    <div>
      <PressHeader/>
      <PostSingle/>
      <NoTop/>
    </div>
  );
}

export default SInglePost;
