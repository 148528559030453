import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import pricing from "../assets/getquote.svg";
import s from "../assets/ls.svg";
import a from "../assets/la.svg";
import h from "../assets/arg.svg";
import v from "../assets/edn.svg";
import c from "../assets/dev.svg";
import z from "../assets/hra.svg";
import k from "../assets/dee.svg";
import o from "../assets/duc.svg";
import sp from "../assets/mo.svg";
import n from "../assets/n.svg";
import p from "../assets/melo.svg";
import { baseUrl } from "../baseUrl";
import axios from "axios";

function GetQuote() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    products: [],
    category: "",
    message: "",
  });

  const handleProdChange = (e) => {
    if (e.target.checked === true) {
      const val = e.target.value;
      setFormData({
        ...formData,
        products: [...formData.products, val],
      });
    } else {
      const val = e.target.value;
      const newProducts = formData.products.filter((p) => p !== val);
      setFormData({ ...formData, products: newProducts });
    }
  };

  const handleInLineRadio = (e) => {
    setFormData({ ...formData, category: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.category || formData.products) return;
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${baseUrl}/api/v1/stackivy/admin/quote/create`,
        formData
      );
      if (data.code === 200) {
        setFormData({
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
          products: [],
          category: "",
          message: "",
        });
        setMessage(data.message);
      }
    } catch (error) {
      console.log(error);
      setMessage(error.message);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setMessage("");
        setFormData({
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
          products: [],
          category: "",
          message: "",
        });
      }, 3000);
    }
  };

  return (
    <section className="getquote">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-6 text-center">
            <img src={pricing} alt="pricing" className="img-fluid" />
            <p className="my-5">
              Please take a moment to fill out the form below, and our support
              team will be in touch with more information on the product.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-10">
            <Form method="POST" onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={formData.first_name}
                      onChange={(e) =>
                        setFormData({ ...formData, first_name: e.target.value })
                      }
                      title="First name is required"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={formData.last_name}
                      onChange={(e) =>
                        setFormData({ ...formData, last_name: e.target.value })
                      }
                      title="Last name is required"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="tel"
                      value={formData.phone}
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                      placeholder="e.g +23480...."
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      required
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                      placeholder="example@example.com"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-5">
                <Form.Label>Choose one or more products</Form.Label>
              </div>
              <div className="mt-3">
                <div className="form-check form-check-inline ">
                  <input
                    className="form-check-input"
                    value={"stackivy"}
                    type="checkbox"
                    name="product"
                    onChange={handleProdChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox1">
                    <img src={s} alt="logo" className="img-fluid" width={67} />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={"Ardilla"}
                    onChange={handleProdChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox2">
                    <img src={a} alt="logo" className="img-fluid" width={67} />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={"Hargon"}
                    onChange={handleProdChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox2">
                    <img src={h} alt="logo" className="img-fluid" width={67} />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={"Vernde"}
                    onChange={handleProdChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox2">
                    <img src={v} alt="logo" className="img-fluid" width={67} />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={"Curved"}
                    onChange={handleProdChange}
                  />

                  <label className="form-check-label" for="inlineCheckbox2">
                    <img src={c} alt="logo" className="img-fluid" width={67} />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={"Zettarh"}
                    onChange={handleProdChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox2">
                    <img src={z} alt="logo" className="img-fluid" width={67} />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={"Onescud"}
                    onChange={handleProdChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox2">
                    <img src={o} alt="logo" className="img-fluid" width={75} />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={"Keed"}
                    onChange={handleProdChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox2">
                    <img src={k} alt="logo" className="img-fluid" width={67} />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={"Nearly"}
                    onChange={handleProdChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox2">
                    <img src={n} alt="logo" className="img-fluid" width={67} />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={"Pomelo"}
                    onChange={handleProdChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox2">
                    <img src={p} alt="logo" className="img-fluid" width={67} />
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={"Spaycemoon"}
                    onChange={handleProdChange}
                  />
                  <label className="form-check-label" for="inlineCheckbox2">
                    <img src={sp} alt="logo" className="img-fluid" width={90} />
                  </label>
                </div>
              </div>
              <div className="mt-5">
                <Form.Label>Select a category</Form.Label>
                <div className="mt-3">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="personal"
                      onChange={handleInLineRadio}
                      required
                    />
                    <label className="form-check-label" for="inlineCheckbox0">
                      Personal
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="Business"
                      onChange={handleInLineRadio}
                      required
                    />
                    <label className="form-check-label" for="inlineCheckbox0">
                      Business
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio3"
                      value="Government"
                      onChange={handleInLineRadio}
                      required
                    />
                    <label className="form-check-label" for="inlineCheckbox0">
                      Government
                    </label>
                  </div>
                </div>
              </div>
              <Form.Group className="my-5">
                <Form.Label>Your message (optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={10}
                  placeholder="Something we should know before our sales team contact you"
                  required
                  value={formData.message}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                />
              </Form.Group>
              <div className="text-center">
                <button
                  className="btn btn-primary btn-green rounded-pill px-5 py-4 mt-5"
                  to=""
                  style={{
                    background:
                      "linear-gradient(89.82deg, #0C6B8A 5.42%, #FFCC00 94.7%)",
                    color: "#fff",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? "Processing..." : " Get a Quote"}
                </button>
              </div>

              <p className="text-center mt-5">{message}</p>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GetQuote;
