import topbar from "../assets/topbar.svg";
import ver from "../assets/topbar-ver.svg";
import ard from "../assets/topbar-ard.svg";
import feature from "../assets/features.svg";
import checklist from "../assets/checklist.svg";
import keed from "../assets/ked.svg";
import ardlogo from "../assets/ard.svg";
import po from "../assets/pooo.svg";
import onwa from "../assets/awn.svg";
import ven from "../assets/vern.svg";
import onw from "../assets/awno.svg";
import pomelo from "../assets/ole.svg";


function PersonalSolution() {
  return (
    <section className="personal-solution bg-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <h6>Personal Solutions</h6>
            <h2 className="my-4 all-in-one">Personal Solutions Pricing</h2>
            <p>Tailored financial solutions for individuals</p>
          </div>
        </div>
        <div className="toppings">
          <div className="row">
            <div className="col-sm-4 mb-3">
              <img src={topbar} alt="top" className="img-fluid w-100" />
              <div className="card-price">
                <h3 className="text-center mb-4">Free</h3>
                <img src={feature} alt="feature" className="img-fluid w-100 mb-4" />
                <div className="fea">
                  <div className="d-flex">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className=""> Financial education tools for Kids and young adults</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className=""> Budgeting courses</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className=""> Saving courses</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className=""> Investing resources</p></div>
                  </div>
                </div>
                <div className="mt-5">
                  <img src={keed} alt="keed" className="img-fluid float-end" />
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <img src={ard} alt="top" className="img-fluid w-100" />
              <div className="card-price">
                <h3 className="text-center mb-4">Free</h3>
                <img src={feature} alt="feature" className="img-fluid w-100 mb-4" />
                <div className="fea">
                  <div className="d-flex">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className=""> Wealth management platform</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className=""> Investment advice</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className=""> Portfolio management</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className=""> Retirement planning</p></div>
                  </div>
                </div>
                <div className="mt-5">
                  <img src={ardlogo} alt="keed" className="img-fluid float-end" />
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <img src={ver} alt="top" className="img-fluid w-100" />
              <div className="card-price">
                <h5 className="text-center" style={{marginBottom: "40px"}}>Starting at Local bank Rate/transaction</h5>
                <img src={feature} alt="feature" className="img-fluid w-100 mb-4" />
                <div className="fea">
                  <div className="d-flex">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className=""> Cross-border payment solution</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className=""> Low fees</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className=""> Fast processing for sending money</p></div>
                  </div>
                </div>
                <div className="mt-5">
                  <img src={ven} alt="keed" className="img-fluid float-end" />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-sm-4 mb-3">
              <img src={onw} alt="top" className="img-fluid w-100" />
              <div className="card-price">
                <h5 className="text-center" style={{marginBottom: "40px"}}>Starting at<br/>N3,500/month – N40,000/yr</h5>
                <img src={feature} alt="feature" className="img-fluid w-100 mb-4" />
                <div className="fea">
                  <div className="d-flex">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Bookkeeping and expense tracking services</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Invoicing</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className=""> Expense tracking</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Tax reporting</p></div>
                  </div>
                </div>
                <div className="mt-5">
                  <img src={onwa} alt="keed" className="img-fluid float-end" />
                </div>
              </div>
            </div>
            <div className="col-sm-4 mb-3">
              <img src={pomelo} alt="top" className="img-fluid w-100" />
              <div className="card-price">
                <h5 className="text-center" style={{marginBottom: "40px"}}>Starting at<br/> N70/Transaction</h5>
                <img src={feature} alt="feature" className="img-fluid w-100 mb-4" />
                <div className="fea">
                  <div className="d-flex">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Payment gateway solution</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Online and in-store payment acceptance</p></div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="me-3"><img src={checklist} alt="checklist" className="img-fluid" /></div>
                    <div><p className="">Credit card and mobile payments</p></div>
                  </div>
                </div>
                <div className="mt-5">
                  <img src={po} alt="keed" className="img-fluid float-end" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PersonalSolution;
