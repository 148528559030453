import FAQContent from "../components/faqContents";
import FAQHero from "../components/faqHero";
// import FAQS from "../components/faqs";
import NoTop from "../components/footerwithoutTop";
import Header from "../components/navBar";


function FAQ() {
  return (
    <div>
      <Header/>
      <FAQHero/>
      {/* <FAQS/> */}
      <FAQContent/>
      <NoTop/>
    </div>
  );
}

export default FAQ;
