import ErrorContent from "../components/errorContent";
import ErrorNav from "../components/errorNav";


function Error() {
  return (
    <div>
      <ErrorNav/>
      <ErrorContent/>
    </div>
  );
}

export default Error;
