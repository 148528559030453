import all from "../assets/alllogos.svg";


function FAQHero() {
  return (
    <section className="pricing">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-9 text-center">
            <h2 className="all-in-one">Frequently Asked Questions (FAQS)</h2>
            <h6 className="my-5">Find answers to our most frequently asked questions. Can’t find the answer you're looking for?<br/>Email our member support team help@stackivy.africa</h6>
            <img src={all} alt="all products" className="img-fluid w-100" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQHero;
