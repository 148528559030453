import React from 'react'
import Header from "../components/navBar";
import FinancialfreedomContent from '../components/financialfreedomContent';
import NoTop from "../components/footerwithoutTop";

function FinancialfreedomGuides() {
  return (
    <div>
     <Header />
    <FinancialfreedomContent />
     <NoTop />

    </div>
  )
}

export default FinancialfreedomGuides;