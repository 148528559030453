import { Link } from "react-router-dom";
import "../styles/companyDropdown.css";

function CompanyDropdown() {
  return (
    <div className="dropdown-menu company-menu ">
      <Link to="/about-us" className="d-flex flex-row mb-2 company-about">
        <svg
          width="78"
          height="72"
          viewBox="0 0 78 72"
          fill="red"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2130_35176)">
            <rect
              x="13"
              y="8"
              width="52"
              height="46"
              rx="9.73913"
              fill="white"
              shapeRendering="crispEdges"
            />
            <path
              className="about"
              d="M38 28H40V26H38M39 39C34.59 39 31 35.41 31 31C31 26.59 34.59 23 39 23C43.41 23 47 26.59 47 31C47 35.41 43.41 39 39 39ZM39 21C37.6868 21 36.3864 21.2587 35.1732 21.7612C33.9599 22.2638 32.8575 23.0003 31.9289 23.9289C30.0536 25.8043 29 28.3478 29 31C29 33.6522 30.0536 36.1957 31.9289 38.0711C32.8575 38.9997 33.9599 39.7362 35.1732 40.2388C36.3864 40.7413 37.6868 41 39 41C41.6522 41 44.1957 39.9464 46.0711 38.0711C47.9464 36.1957 49 33.6522 49 31C49 29.6868 48.7413 28.3864 48.2388 27.1732C47.7362 25.9599 46.9997 24.8575 46.0711 23.9289C45.1425 23.0003 44.0401 22.2638 42.8268 21.7612C41.6136 21.2587 40.3132 21 39 21M38 36H40V30H38V36Z"
              fill="#1E2C31"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_2130_35176"
              x="0.826086"
              y="0.695652"
              width="76.3478"
              height="70.3478"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4.86957" />
              <feGaussianBlur stdDeviation="6.08696" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.117647 0 0 0 0 0.172549 0 0 0 0 0.192157 0 0 0 0.16 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2130_35176"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2130_35176"
                result="shape"
              />
            </filter>
          </defs>
        </svg>

        <div className="title-header mt-3">
          <h4>About Us</h4>
          <p>Know Us More</p>
        </div>
      </Link>

      <Link
        to="/privacypolicy"
        className="d-flex flex-row mb-2 company-privacy"
      >
        <svg
          width="78"
          height="72"
          viewBox="0 0 78 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2130_35190)">
            <rect
              x="13"
              y="8"
              width="52"
              height="46"
              rx="9.73913"
              fill="white"
              shapeRendering="crispEdges"
            />
            <path
              className="privacy"
              d="M39 22.19L46 25.3V30C46 34.52 43.02 38.69 39 39.93C34.98 38.69 32 34.52 32 30V25.3L39 22.19ZM39 20L30 24V30C30 35.55 33.84 40.74 39 42C44.16 40.74 48 35.55 48 30V24L39 20ZM38 26H40V28H38V26ZM38 30H40V36H38V30Z"
              fill="#1E2C31"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_2130_35190"
              x="0.826086"
              y="0.695652"
              width="76.3478"
              height="70.3478"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4.86957" />
              <feGaussianBlur stdDeviation="6.08696" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.117647 0 0 0 0 0.172549 0 0 0 0 0.192157 0 0 0 0.16 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2130_35190"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2130_35190"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
        <div className="title-header mt-3">
          <h4>Privacy Policy</h4>
          <p>Security</p>
        </div>
      </Link>
      <Link to="/terms&conditions" className="d-flex flex-row company-terms">
        <svg
          width="78"
          height="72"
          viewBox="0 0 78 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2130_35197)">
            <rect
              x="13"
              y="8"
              width="52"
              height="46"
              rx="9.73913"
              fill="white"
              shapeRendering="crispEdges"
            />
            <path
              className="terms"
              d="M32 38H41V34C41 33.7167 41.096 33.479 41.288 33.287C41.4793 33.0957 41.7167 33 42 33H46V24H32V38ZM32 40C31.45 40 30.979 39.8043 30.587 39.413C30.1957 39.021 30 38.55 30 38V24C30 23.45 30.1957 22.979 30.587 22.587C30.979 22.1957 31.45 22 32 22H46C46.55 22 47.021 22.1957 47.413 22.587C47.8043 22.979 48 23.45 48 24V34L42 40H32ZM35 33C34.7167 33 34.479 32.904 34.287 32.712C34.0957 32.5207 34 32.2833 34 32C34 31.7167 34.0957 31.479 34.287 31.287C34.479 31.0957 34.7167 31 35 31H38C38.2833 31 38.521 31.0957 38.713 31.287C38.9043 31.479 39 31.7167 39 32C39 32.2833 38.9043 32.5207 38.713 32.712C38.521 32.904 38.2833 33 38 33H35ZM35 29C34.7167 29 34.479 28.904 34.287 28.712C34.0957 28.5207 34 28.2833 34 28C34 27.7167 34.0957 27.479 34.287 27.287C34.479 27.0957 34.7167 27 35 27H43C43.2833 27 43.5207 27.0957 43.712 27.287C43.904 27.479 44 27.7167 44 28C44 28.2833 43.904 28.5207 43.712 28.712C43.5207 28.904 43.2833 29 43 29H35ZM32 38V24V38Z"
              fill="#1E2C31"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_2130_35197"
              x="0.826086"
              y="0.695652"
              width="76.3478"
              height="70.3478"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4.86957" />
              <feGaussianBlur stdDeviation="6.08696" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.117647 0 0 0 0 0.172549 0 0 0 0 0.192157 0 0 0 0.16 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2130_35197"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2130_35197"
                result="shape"
              />
            </filter>
          </defs>
        </svg>

        <div className="title-header mt-3">
          <h4>Terms & Conditions</h4>
          <p>User Policies</p>
        </div>
      </Link>
    </div>
  );
}

export default CompanyDropdown;
