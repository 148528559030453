import React, { useEffect, useState } from "react";
import Header from "./navBar";
import NoTop from "./footerwithoutTop";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../baseUrl";

function Singleblogpost() {
  const { id } = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    const getPost = async () => {
      try {
        const { data } = await axios.get(
          `${baseUrl}/api/v1/stackivy/admin/marketing/blog/${id}`
        );
        console.log({ data });
        setData(data.blogs);
      } catch (error) {
        console.log(error);
      }
    };
    getPost();
  }, []); //eslint-disable-line

  return (
    <div>
      <Header />
      {/* <BookeepingContent /> */}
      <section className="finance-content">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="row justify-content-center">
                <div className="col-md-9 ">
                  <div className="text-center mt-5 mb-4">
                    {/* <p className="mb-3 mt-5 "> Finance </p> */}
                    <h1>{data?.title}</h1>
                    {/* <img src={wot} alt="4 reasons you need bookingkeeping " className="img-fluid w-100 rounded"  /> */}
                    <p className="mt-3">
                      {data?.summary}
                      {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto nostrum voluptatem quaerat fugiat, eos delectus obcaecati reprehenderit provident necessitatibus eius optio, assumenda voluptatum expedita voluptatibus! Accusamus dolor quidem natus molestias! */}
                    </p>
                    <div className="d-flex align-items-start justify-content-center my-4">
                      {" "}
                      {/* <img src={pic} alt="" className="img-fluid mx-2" />{" "} */}
                      <p>
                        {" "}
                        {data?.author} <span> 10 Feb 2023</span>{" "}
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" row justify-content-center">
              <div className="col-md-9">
                {data && (
                  <div
                    className="ql-editor text-center"
                    dangerouslySetInnerHTML={{
                      __html: data?.blog_contents?.post,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <NoTop />
    </div>
  );
}

export default Singleblogpost;
