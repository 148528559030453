import { Form } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { useState } from "react";
import axios from "axios";
import "../styles/partnershipForm.css";

const fileTypes = ["JPG", "PNG"];

function PartnershipForm() {
  const [pfile, setPFile] = useState(null);
  const [bfile, setBFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (file) => setPFile(file);
  const handleBChange = (file) => setBFile(file);
  const [formData, setFormData] = useState({
    startup_name: "",
    startup_website: "",
    startup_stage: "",
    startup_industry: "",
    startup_country: "",
    amount_needed: "",
    amount_needed_next_three_years: "",
    advanced_cost_warm_introductions: "",
    amount_invested_by_founders: "",
    amount_invested_by_external_investors: "",
    person_name: "",
    linkedin_profile: "",
    contact_email: "",
    additional_email: "",
    phone: "",
    whatsapp_phone: "",
    position: "",
    best_way_to_follow_up: "",
    how_did_you_hear_about_us: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form_data = new FormData();
    Object.entries(formData).forEach(([key, value]) =>
      form_data.append(key, value)
    );
    form_data.append("pitch_deck", pfile);
    form_data.append("business_plan", bfile);

    try {
      setIsLoading(true);
      const { data } = await axios.post(
        "https://stackivy-admin-be.onrender.com/api/v1/stackivy/admin/startup",
        form_data
      );
      console.log({ data });
      if (data.code === 200) {
        setMessage("Form Submitted Successfully");
      }
    } catch (error) {
      console.log({ error });
      setMessage(error.message);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setFormData({
          startup_name: "",
          startup_website: "",
          startup_stage: "",
          startup_industry: "",
          startup_country: "",
          amount_needed: "",
          amount_needed_next_three_years: "",
          advanced_cost_warm_introductions: "",
          amount_invested_by_founders: "",
          amount_invested_by_external_investors: "",
          person_name: "",
          linkedin_profile: "",
          contact_email: "",
          additional_email: "",
          phone: "",
          whatsapp_phone: "",
          position: "",
          best_way_to_follow_up: "",
          how_did_you_hear_about_us: "",
        });
        setMessage("");
        setBFile(null);
        setPFile(null);
      }, 3000);
    }
  };
  return (
    <section className="partner-form">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-6 text-center">
            <h2>Apply For Partnership Form</h2>
            <p className="mt-4">
              The form helps us understand your business and how we can help you
              <br />
              achieve your financial goals.
            </p>
          </div>
        </div>
        <div className="form mt-5">
          <div className="row justify-content-center">
            <div className="col-sm-6">
              <Form onSubmit={handleSubmit}>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Startup Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.startup_name}
                      name="startup_name"
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Startup Website</Form.Label>
                    <Form.Control
                      type="text"
                      name="startup_website"
                      required
                      value={formData.startup_website}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Startup Stage</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={formData.startup_stage}
                      name="startup_stage"
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    >
                      <option value="Incubation">Incubation</option>
                      <option value="Pre Seed">Pre Seed</option>
                      <option value="Seed Series A">Seed Series A</option>
                      <option value="Seed Series B">Seed Series B</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Startup Industry</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.startup_industry}
                      required
                      name="startup_industry"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Startup Country</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.startup_country}
                      name="startup_country"
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Amount Needed</Form.Label>
                    <p>
                      Please insert the amount needed for your startup in the
                      current round of funding that you expect to get in the
                      coming six months Your answer
                    </p>
                    <Form.Control
                      type="text"
                      value={formData.amount_needed}
                      required
                      name="amount_needed"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Amount needed for the next 3 years</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.amount_needed_next_three_years}
                      name="amount_needed_next_three_years"
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      Advanced Cost for Warm introductions
                    </Form.Label>
                    <p>
                      In this program we do warm introductions to angel
                      investors and VCs. Reviewers and Internal angel: we share
                      your pitch deck with a close circle of angel investors and
                      we ask for their feedback. You can check the estimated
                      number of angel investors and VCs that we will introduce
                      you to based on the payment you choose. These numbers are
                      just estimations. You will get the accurate numbers in an
                      official offer from us after assessing your startup.
                    </p>
                    <Form.Select
                      aria-label="Default select example"
                      name="advanced_cost_warm_introductions"
                      value={formData.advanced_cost_warm_introductions}
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    >
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      How much has been invested by founders so far?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="amount_invested_by_founders"
                      required
                      value={formData.amount_invested_by_founders}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      How much has been invested by external investors so far?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="amount_invested_by_external_investors"
                      value={formData.amount_invested_by_external_investors}
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Upload Pitch Deck</Form.Label>
                    <p>
                      You can skip this if you have not prepared the document
                      yet?
                    </p>
                    <FileUploader
                      handleChange={handleChange}
                      name="pitch_deck"
                      types={fileTypes}
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Upload Business Plan</Form.Label>
                    <p>
                      You can skip this if you have not prepared the document
                      yet
                    </p>
                    <FileUploader
                      handleChange={handleBChange}
                      name="business_plan"
                      types={fileTypes}
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Person Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="person_name"
                      value={formData.person_name}
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Linkedin Profile</Form.Label>
                    <Form.Control
                      type="text"
                      name="linkedin_profile"
                      value={formData.linkedin_profile}
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Contact Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_email"
                      value={formData.contact_email}
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Additional Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="additional_email"
                      value={formData.additional_email}
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      value={formData.phone}
                      required
                      placeholder="e.g +23481..."
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Whatsapp Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="whatsapp_phone"
                      value={formData.whatsapp_phone}
                      placeholder="e.g +23481..."
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Position</Form.Label>
                    <Form.Control
                      type="text"
                      name="position"
                      value={formData.position}
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>The best way to follow up with me</Form.Label>
                    <Form.Control
                      type="text"
                      name="best_way_to_follow_up"
                      value={formData.best_way_to_follow_up}
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="fm-card mb-4">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>How did you hear about us?</Form.Label>
                    <Form.Control
                      type="text"
                      name="how_did_you_hear_about_us"
                      value={formData.how_did_you_hear_about_us}
                      required
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>

                <button
                  className="submit-btn"
                  style={{
                    background:
                      "linear-gradient(85.3deg, #00AC10 9.48%, #02A6B0 87.92%)",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? "Processing ..." : "Submit"}
                </button>
                <p className="form-r">{message}</p>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnershipForm;
