import pricing from "../assets/pricing.svg";


function PricingHero() {
  return (
    <section className="pricing">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-6 text-center">
            <img src={pricing} alt="pricing" className="img-fluid" />
            <p className="mt-4">Choose the Right Plan for Your Financial Needs</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingHero;
