import { useEffect, useState } from "react";
import BeSpeaker from "../components/beSpeaker";
// import BuildingSus from "../components/buildingSustainable";
import Footer from "../components/footer";
import PastWebinar from "../components/pastWebinar";
// import Upcoming from "../components/upcomingEvents";
import WebHeader from "../components/webHeader";
import WebinarHero from "../components/webinarHero";
import axios from "axios";
import { baseUrl } from "../baseUrl";

function Webinar() {
  const [isLoading, setIsLoading] = useState(false);
  const [webinar, setWebinar] = useState([]);
  useEffect(() => {
    const getWebinars = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `${baseUrl}/api/v1/stackivy/admin/marketing/webinar`
        );
        if (data.code === 200) {
          setWebinar(data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getWebinars();
  }, []);

  return (
    <div>
      <WebHeader />
      <WebinarHero />

      {/* {webinar.map((w) =>
        w.inactive_webinar.map((W, i) =>
          i % 2 > 0 ? (
            <PastWebinar data={W} key={i} />
          ) : (
            <BuildingSus key={i} data={W} />
          )
        )
      )} */}
      {/* working now */}
      <PastWebinar data={webinar} isLoading={isLoading} />

      {/* <PastWebinar /> */}
      {/* <BuildingSus /> */}
      {/* <Upcoming /> */}
      <BeSpeaker />
      <Footer />
    </div>
  );
}

export default Webinar;
