import { Link } from "react-router-dom";
import error from "../assets/error.svg";



function ErrorContent () {
  return (
    <section className="error-content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-6 mb-3">
            <img src={error} alt="error" className="img-fluid w-100" />
          </div>
          <div className="col-sm-6 text-center error-right">
            <h2 className="all-in-one">Oops!</h2>
            <h6 className="my-5">We can’t seem to find the page<br/>you’re looking for.</h6>
            <Link
              to="/"
              className="btn btn-primary btn-green rounded-pill px-5 py-4"
              style={{background: 'linear-gradient(117.04deg, #0C6B8A -13.79%, #FFCC00 131.52%)'}}
            >
              Return to Homepage
            </Link>
            <p className="mt-4">Visit our help center</p>
          </div>
        </div>
      </div>
    </section>
    
  )
}


export default ErrorContent