import NoTop from "../components/footerwithoutTop";
import Header from "../components/navBar";
import PartnershipForm from "../components/partnershipForm";

function Partnership() {
  return (
    <div>
      <Header/>
      <PartnershipForm/>
      <NoTop/>
    </div>
  );
}

export default Partnership;
