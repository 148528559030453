import React from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import onw from "../assets/Onwa1.png";
import kid from "../assets/Keed1.png";
import one from "../assets/Onescud1.png";
import mon from "../assets/Spaycemoon1.png";
import curv from "../assets/Curved1.png";
import ard from "../assets/Ardilla1.png";
import hard from "../assets/Hargon1.png";
import near from "../assets/Nearly1.png";
import ver from "../assets/Vernde1.png";
import set from "../assets/Zettarh1.png";
import pam from "../assets/Pomelo1.png";

import ardilla from "../assets/dill00.png";
import vernde from "../assets/vend1.png";
import zetta from "../assets/zet1.png";
import onescud from "../assets/one1.png";
import keed from "../assets/kid1.png";
import pomelo from "../assets/pam1.png";
import onwa from "../assets/onw1.png";
import hargon from "../assets/frame40.png";
import moon from "../assets/space1.png";
import nearly from "../assets/near1.png";
import curved from "../assets/curve1.png";

import zet from "../assets/Do more with Stackivy Products.png";
// import { Icon } from "@iconify/react";

function ProductHome() {
  return (
    <section className="bg-light  sup-faq">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col className="product-side" sm={12}>
                  <div>
                    <img src={zet} alt="about-us" className="img-fluid my-5" />
                  </div>
                  <div className=" d-flex align-items-start justify-content-between my-5">
                  <Nav variant="pills" className="mx-5" >
                      <div className="d-flex align-items-start justify-content-between">
                        <div>
                          <Nav.Item className="">
                            <Nav.Link eventKey="first">
                              {" "}
                              <img
                                src={onwa}
                                alt=""
                                className="img-fluid"
                              />{" "}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="">
                            <Nav.Link eventKey="second">
                              <img
                                src={keed}
                                alt=""
                                className="img-fluid"
                              />
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="">
                            <Nav.Link eventKey="third">
                              <img
                                src={onescud}
                                alt=""
                                className="img-fluid"
                              />
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="">
                            <Nav.Link eventKey="fourth">
                              <img
                                src={moon}
                                alt=""
                                className="img-fluid"
                              />
                            </Nav.Link>
                          </Nav.Item>
                        </div>

                        <div>
                          <Nav.Item className="">
                            <Nav.Link eventKey="fifth">
                              <img src={curved} alt="" className="img-fluid" />
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="">
                            <Nav.Link eventKey="sixth">
                              <img src={ardilla} alt="" className="img-fluid" />
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="">
                            <Nav.Link eventKey="seventh">
                              <img src={hargon} alt="" className="img-fluid" />
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="">
                            <Nav.Link eventKey="eighth">
                              <img
                                src={nearly}
                                alt=""
                                className="img-fluid"
                              />
                            </Nav.Link>
                          </Nav.Item>
                        </div>
                        <div>
                          <Nav.Item className="">
                            <Nav.Link eventKey="nineth">
                              <img src={vernde} alt="" className="img-fluid" />
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="">
                            <Nav.Link eventKey="tenth">
                              <img src={zetta} alt="" className="img-fluid" />
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="">
                            <Nav.Link eventKey="eleventh">
                              <img src={pomelo} alt="" className="img-fluid" />
                            </Nav.Link>
                          </Nav.Item>
                        </div>
                      </div>
                    </Nav>
                  
                  <Tab.Content >
                    <Tab.Pane eventKey="first">
                      <div>
                        <img src={onw} alt="about-us" className="img-fluid  " />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div>
                        <img src={kid} alt="" className="img-fluid  " />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div>
                        <img src={one} alt="" className="img-fluid  " />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <div>
                        <img src={mon} alt="" className="img-fluid  " />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      <div>
                        <img src={curv} alt="" className="img-fluid " />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="sixth">
                      <div>
                        <img src={ard} alt="" className="img-fluid  " />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="seventh">
                      <div>
                        <img
                          src={hard}
                          alt="about-us"
                          className="img-fluid  mx-4"
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="eighth">
                      <div>
                        <img
                          src={near}
                          alt="about-us"
                          className="img-fluid  mx-4"
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="nineth">
                      <div>
                        <img
                          src={ver}
                          alt="about-us"
                          className="img-fluid  mx-4"
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tenth">
                      <div>
                        <img
                          src={set}
                          alt="about-us"
                          className="img-fluid  mx-4"
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="eleventh">
                      <div>
                        <img
                          src={pam}
                          alt="about-us"
                          className="img-fluid  mx-4"
                        />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  </div>
                   
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductHome;
