import NoTop from "../components/footerwithoutTop";
import NearlyHeader from "../components/nearlyHeader";
import NearlyHero from "../components/nearlyHero";



function Nearly() {
  return (
    <div>
      <NearlyHeader/>
      <NearlyHero/>
      <NoTop/>
    </div>
  );
}

export default Nearly;
