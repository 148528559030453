import React from 'react'
import Header from "../components/navBar";
import CustomapplicationContent from '../components/customapplicationContent';
import NoTop from "../components/footerwithoutTop";

function CustomGuides() {
  return (
    
    <div> 
    <Header />
    <CustomapplicationContent />
     <NoTop />
  </div>
  );
}




export default CustomGuides;