import hero from "../assets/objects.svg";

function Roady() {
  return (
    <section className="roadyyy">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center px-0">
            <img src={hero} alt="revenue" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roady;
